html {
  scroll-behavior: smooth;
}

body {
  margin-bottom: 80px;
}

main {
  animation: fadein 3s;
  margin: 60px auto;
}

i {
  font-style: italic;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

nav {
  z-index: 1;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  font-family: "Cutive Mono";
  font-weight: 300;
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
  }

  li {
    background: #fff;
    color: inherit;
    border: 1px solid #000;
    border-right: 0;
    padding: 5px 10px;
    line-height: 1;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 200ms, color 200ms, width 200ms;
    text-align: center;
    display: inline-block;
    letter-spacing: 1px;
    text-decoration: none;

    &:last-child {
      border-right: 1px solid #000;
    }
  }
}
header {
  max-width: 1000px;
  margin: auto;
  span {
    font-family: "Nimbus Sans L";
    font-weight: 300;
    font-size: 24px;
    margin: 20px 0 12px;
    display: block;
  }
}

.textSection {
  margin: 20px auto;
  max-width: 1000px;
}

h1 {
  font-family: "Domaine Display Condensed";
  font-size: 60px;
}

p {
  font-family: "Nimbus Sans L";
  font-weight: 300;
  font-size: 17px;
  line-height: 1.2;
}

@media (max-width: 1200px) {
  header {
    p,
    p span {
      font-size: 20px;
    }
  }
}

h2 {
  font-size: 40px;
  font-family: "Cutive Mono";
  padding: 9px 0;
  border: 1px solid #000;
}

.second {
  margin-top: 20px;
  text-align: center;

  h2 {
    font-size: 20px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .grazeSection {
    border: 1px solid #000;
    border-top: none;

    p {
      padding: 18px;

      &:first-child {
        padding-bottom: 0;
      }
    }
  }
  .grazeLogo {
    height: 50px;
    margin-right: 10px;
  }

  p {
    text-align: left;
  }
}

li {
  display: flex;
  padding: 20px;
  font-size: 18px;
  font-family: "Cutive Mono";
  text-align: left;
  align-items: center;

  a {
    text-transform: uppercase;
    text-decoration: underline;
    font-family: "Nimbus Sans L";
    transition: background-color 200ms, color 200ms, width 200ms;
    cursor: pointer;
    color: inherit;
  }
}

.third {
  text-align: center;
  p {
    text-align: left;
  }
  li {
    padding: 8px 20px;
    font-size: 16px;
  }

  ul {
    padding-bottom: 20px;
  }

  .innerRight {
    justify-content: flex-start;

    a {
      display: block;
      color: inherit;
      font-family: "Cutive Mono";
      margin-top: 10px;
    }
  }

  .innerRight p:first-of-type {
    border-bottom: 1px solid #000;
    font-family: "Cutive Mono";
    text-align: left;
  }
}

.innerSections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #000;
  div {
    p {
      padding: 20px;
    }

    &:first-child {
      border-right: 1px solid #000;
    }
  }
}

h3 {
  font-family: "Domaine Display Condensed";
  font-size: 60px;
  border-bottom: 1px solid #000;
  padding: 0 0 15px;
}

.innerLeft {
  p {
    border-bottom: 1px solid #000;
    font-family: "Cutive Mono";
    text-align: center;
  }

  span {
    font-family: "Nimbus Sans L";
    font-size: 25px;
    display: block;
    padding-top: 25px;

    img {
      height: 30px;
      margin-right: 10px;
      vertical-align: text-top;
    }
  }
}

.innerRight {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
  justify-content: center;
}

.bg {
  background-image: url("./images/bg.png");
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -9;
  transition: filter 200ms;
  animation: filterIt 8s infinite alternate;
}

@keyframes filterIt {
  from {
    filter: hue-rotate(0deg) blur(100px);
  }
  to {
    filter: hue-rotate(45deg) blur(100px);
  }
}

.final {
  display: grid;
  grid-template-columns: 1fr 1fr;

  a {
    color: inherit;
  }
  h4 {
    font-family: "Nimbus Sans L";
    font-size: 30px;
    text-align: right;

    img {
      height: 50px;
      vertical-align: middle;
    }
  }

  .contact .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    line-height: 1.5;
  }
}

.final h3 {
  border-bottom: none;
}

.contact {
  .profile {
    width: 180px;
  }
}

.wolfRose {
  font-family: "Nimbus Sans L";
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;

  img {
    width: 300px;
  }
}

form {
  font-family: "Nimbus Sans L";
  display: flex;
  flex-direction: column;
}

label {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

input,
textarea {
  border-radius: 2px;
  border: 1px solid #000;
  background: unset;
  padding: 4px;
  resize: none;
  font-size: 16px;
  appearance: none;
}

button {
  border-radius: 50px;
  text-transform: uppercase;
  padding: 4px 4px 6px;
  width: 80px;
  height: 22px;
  margin-top: 20px;
  background: #000;
  border: none;
  color: #fff;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background: none;
    color: #000;
  }
}

.link {
  border-top: 1px solid #000;
  padding: 20px 20px 13px;
  a {
    color: #000;
  }
  .brand img {
    max-width: 200px;
    max-height: 200px;
  }

  .images {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 90%;
      margin: 10px auto;
      object-fit: cover;
    }
  }
}

@media (max-width: 1100px) {
  .app {
    padding: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.2;
  }

  .final {
    display: flex;
    flex-direction: column-reverse;
  }

  .innerSections {
    display: flex;
    flex-direction: column;
  }

  nav {
    max-width: 95%;

    li,
    a {
      font-size: 14px;
    }
  }

  h3,
  h2 {
    font-size: 30px;
  }

  h3 {
    padding: 0 0 10px;
  }

  .textSection,
  header {
    max-width: 500px;
  }

  .innerSections div p {
    padding: 8px;
    font-size: 16px;
  }

  .innerLeft {
    border-right: none !important;
  }

  li {
    font-size: 16px;
  }

  .innerLeft span {
    font-size: 22px;
  }

  li img {
    width: 35px;
  }

  main {
    margin: 20px auto;
  }

  body {
    margin-bottom: 0;
  }

  .final .contact .flex {
    justify-content: space-between;
  }

  .final h4 {
    font-size: 20px;
  }
  .final h4 img {
    height: 21px;
  }

  .contact {
    margin-bottom: 20px;
  }
}
@media (max-width: 900px) {
  .second h2 {
    font-size: 14px;
  }
  nav {
    display: none;
  }

  main {
    margin-top: 0;
  }
  header {
    margin: 0;
  }

  .innerSections .innerRight {
    border-top: 1px solid black;
  }
  .third .innerRight p:first-of-type {
    text-align: center;
  }

  .link {
    .images {
      img {
        width: 90%;
      }
    }

    .brand img {
      max-height: 100px;
    }
  }

  .contact {
    .profile {
      width: 120px;
    }
  }

  .final {
    h4 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
    }
  }
}
